@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}
html {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
  font-family: 'roboto', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  margin: inherit;
  padding: inherit;
  background-color: #e5f6fd;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}

ol {
  counter-reset: item;
}

ol > li {
  counter-increment: item;
}

ol ol > li {
  display: block;
  position: relative;
}

ol ol > li:before {
  content: counters(item, '.') '. ';
  margin-left: -20px;
  position: absolute;
  left: -12px;
}

.PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.calendesk-frame {
  width: 100%;
  display: block;
  border: none;
  margin: 0 auto;
  background-color: #e5f6fd;
}

@media only screen and (max-width: 600px) {
  .calendesk-frame {
    height: calc(100vh - 58px);
  }
}
@media only screen and (min-width: 600px) {
  .calendesk-frame {
    height: calc(100vh - 64px);
  }
}

